import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import {
  Agreement,
  CondensedUser,
  LoginResponse,
  PasswordChangeRequest,
  PasswordResetRequest,
  User,
  UserSignedAgreement,
} from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class UserDataService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _http: HttpClient,
  ) {}

  getUsers(): Observable<Array<User>> {
    const url = this._dataService.usersUrl();
    return this._http.get<Array<User>>(url);
  }

  getCondensedUsers(
    includeInstitutions?: boolean,
  ): Observable<Array<CondensedUser>> {
    const url = this._dataService.usersUrl(true, includeInstitutions);
    return this._http.get<Array<CondensedUser>>(url);
  }

  getUser(userId: string): Observable<User> {
    const url = this._dataService.userUrl(userId);
    return this._http.get<User>(url);
  }

  getUserReleaseTags(userId: string): Observable<Array<string>> {
    const url = this._dataService.userReleaseTagsUrl(userId);
    return this._http.get<Array<string>>(url);
  }

  createUser(user: User): Observable<User> {
    const url = this._dataService.userUrl();
    return this._http.put<User>(url, user);
  }

  updateUser(user: User): Observable<User> {
    const url = this._dataService.userUrl();
    return this._http.post<User>(url, user);
  }

  updateUserPassword(
    request: PasswordChangeRequest,
  ): Observable<LoginResponse> {
    const url = this._dataService.userPasswordUrl();
    return this._http.post<LoginResponse>(url, request);
  }

  resetUserPassword(request: PasswordResetRequest): Observable<void> {
    const url = this._dataService.userPasswordResetUrl();
    return this._http.post<void>(url, request);
  }

  getUnsignedAgreements(userId: string): Observable<Array<Agreement>> {
    const url = this._dataService.getUserUnsignedAgreementsRequestUrl(userId);
    return this._http.get<Array<Agreement>>(url);
  }

  signAgreement(
    userId: string,
    agreementId: string,
  ): Observable<UserSignedAgreement> {
    const url = this._dataService.getAcceptAgreementRequestUrl(
      userId,
      agreementId,
    );
    return this._http.post<UserSignedAgreement>(url, {});
  }

  unsignAgreement(userId: string, agreementId: string): Observable<void> {
    const url = this._dataService.deleteUserSignedAgreementUrl(
      userId,
      agreementId,
    );
    return this._http.request<void>('delete', url);
  }
}
