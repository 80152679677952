import { Action, ActionReducer, createReducer } from '@ngrx/store';
import * as AppStoreActions from './app-store.actions';
import { AppState, initialState } from './app-store.state';

function isHydrateAppStateSucceeded(
  action: Action,
): action is ReturnType<typeof AppStoreActions.hydrateAppStateSucceeded> {
  return action.type === AppStoreActions.hydrateAppStateSucceeded.type;
}

export const hydrationMetaReducer = (
  reducer: ActionReducer<AppState>,
): ActionReducer<AppState> => {
  return (state, action) => {
    if (isHydrateAppStateSucceeded(action)) {
      return action.state;
    } else {
      return reducer(state, action);
    }
  };
};

const rootReducer = createReducer(initialState);

export function reducer(state: AppState | undefined, action: Action) {
  return rootReducer(state, action);
}
