import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { ScenarioPackageItem } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class ScenarioPackageItemDataService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _http: HttpClient,
  ) {}

  getScenarioPackageItemsForScenarioPackage(
    scenarioPackageId: string,
    includes?: any,
  ): Observable<Array<ScenarioPackageItem>> {
    const url = this._dataService.scenarioPackageItemsUrl(
      scenarioPackageId,
      includes,
    );
    return this._http.get<Array<ScenarioPackageItem>>(url);
  }

  setScenarioPackageItemsForScenarioPackage(
    scenarioPackageId: string,
    scenarioPackageItems: Array<ScenarioPackageItem>,
    includes?: any,
  ): Observable<Array<ScenarioPackageItem>> {
    const url = this._dataService.scenarioPackageItemsUrl(
      scenarioPackageId,
      includes,
    );
    return this._http.post<Array<ScenarioPackageItem>>(
      url,
      scenarioPackageItems,
    );
  }

  createScenarioPackageItem(
    scenarioPackageItem: ScenarioPackageItem,
  ): Observable<ScenarioPackageItem> {
    const url = this._dataService.scenarioPackageItemUrl();
    return this._http.put<ScenarioPackageItem>(url, scenarioPackageItem);
  }

  deleteScenarioPackageItem(
    scenarioPackageItem: ScenarioPackageItem,
  ): Observable<void> {
    const url = this._dataService.scenarioPackageItemUrl();
    const options = {
      body: scenarioPackageItem,
    };
    return this._http.request<void>('delete', url, options);
  }
}
