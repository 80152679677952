// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simx-entity-detail-container {
  position: relative;
  min-height: 32px;
}

.simx-entity-detail--loading-indicator-container {
  position: absolute;
  top: 0;
  right: 0;
}

.simx-entity-detail--initialization-error-container {
  display: flex;
  align-items: center;
  height: 32px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/ui-elements/components/entity-detail/entity-detail/entity-detail.component.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,gBAAA;AADF;;AAIA;EACE,kBAAA;EACE,MAAA;EACA,QAAA;AADJ;;AAIA;EACE,aAAA;EACE,mBAAA;EACF,YAAA;AADF","sourcesContent":["@import 'variables';\n\n.simx-entity-detail-container {\n  position: relative;\n  min-height: 32px; // Height of the loading indicator\n}\n\n.simx-entity-detail--loading-indicator-container {\n  position: absolute;\n    top: 0;\n    right: 0;\n}\n\n.simx-entity-detail--initialization-error-container {\n  display: flex;\n    align-items: center;\n  height: 32px; // Height of the loading indicator\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
