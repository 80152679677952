import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { Institution } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class InstitutionDataService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _http: HttpClient,
  ) {}

  getInstitutions(): Observable<Array<Institution>> {
    const url = this._dataService.institutionsUrl();
    return this._http.get<Array<Institution>>(url);
  }

  getInstitution(
    institutionId: string,
    returnValueOptions?: any,
  ): Observable<Institution> {
    const url = this._dataService.institutionUrl(
      institutionId,
      returnValueOptions,
    );
    return this._http.get<Institution>(url);
  }

  createInstitution(institution: Institution): Observable<Institution> {
    const url = this._dataService.institutionUrl();
    return this._http.put<Institution>(url, institution);
  }

  updateInstitution(institution: Institution): Observable<Institution> {
    const url = this._dataService.institutionUrl();
    return this._http.post<Institution>(url, institution);
  }

  reactivateInstitution(institutionId: string): Observable<Institution> {
    const url = this._dataService.reactivateInstitutionUrl();
    return this._http.post<Institution>(url, { institutionId });
  }
}
