// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simx-accordion-list {
  list-style: none;
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/ui-elements/components/controls/accordion-list/accordion-list.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,SAAA;EACA,UAAA;AACF","sourcesContent":[".simx-accordion-list {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
