import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import * as AppStateActions from './app-store.actions';
import { CoreService } from '@simx/modules/core/services';

const LocalStorageItemName = 'simxAdminPortal';

@Injectable()
export class AppStoreEffects implements OnInitEffects {
  constructor(
    private _actions$: Actions,
    private _coreService: CoreService,
    private _store: Store,
  ) {}

  ngrxOnInitEffects(): Action {
    return AppStateActions.hydrateAppState();
  }

  hydrateAppState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AppStateActions.ActionTypes.HydrateAppState),
      map(() => {
        const localStorageItem = localStorage.getItem(LocalStorageItemName);
        if (!localStorageItem) return AppStateActions.hydrateAppStateFailed();

        let parsedLocalStorageItem;
        try {
          parsedLocalStorageItem = JSON.parse(localStorageItem);
        } catch {
          localStorage.removeItem(LocalStorageItemName);
        }

        const appReleaseVersion = this._coreService.getAppReleaseVersion();
        if (
          parsedLocalStorageItem &&
          parsedLocalStorageItem.app?.appReleaseVersion === appReleaseVersion
        ) {
          return AppStateActions.hydrateAppStateSucceeded({
            state: parsedLocalStorageItem,
          });
        } else {
          return AppStateActions.hydrateAppStateFailed();
        }
      }),
    ),
  );

  storeAppState$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          AppStateActions.ActionTypes.HydrateAppStateSucceeded,
          AppStateActions.ActionTypes.HydrateAppStateFailed,
        ),
        switchMap(() => this._store),
        distinctUntilChanged(),
        tap(state => {
          localStorage.setItem(LocalStorageItemName, JSON.stringify(state));
        }),
      ),
    {
      dispatch: false,
    },
  );
}
