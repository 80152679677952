import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { environment } from '@simx/env';
import { SharedModule } from '@simx/shared/module';
import { AuthenticationModule } from '@simx/modules/authentication';
import { allComponents, publicComponents } from './components';
import {
  CorsInterceptor,
  ErrorHandlerInterceptor,
  TokenInterceptor,
  UnauthorizedInterceptor,
} from './interceptors';
import { AppRoutingModule } from './routing';
import { AppLoggingService } from './services';

const dataRequestProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CorsInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: UnauthorizedInterceptor,
    multi: true,
  },
];
const errorLoggingProviders = [
  {
    provide: ErrorHandler,
    useClass: ErrorHandlerInterceptor,
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
];

@NgModule({
  declarations: [...allComponents],
  imports: [
    AppRoutingModule,
    AuthenticationModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
  ],
  exports: [...publicComponents],
  providers: [
    ...(environment.logErrors ? errorLoggingProviders : []),
    ...dataRequestProviders,
  ],
})
export class CoreModule {
  constructor(private readonly _appLoggingService: AppLoggingService) {
    this._appLoggingService.initializeErrorLogging();
  }
}
