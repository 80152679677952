import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { ScenarioRelease } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class ScenarioReleaseDataService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _http: HttpClient,
  ) {}

  getScenarioReleasesForScenario(
    scenarioId: string,
  ): Observable<Array<ScenarioRelease>> {
    const url = this._dataService.scenarioReleasesUrl(scenarioId);
    return this._http.get<Array<ScenarioRelease>>(url);
  }

  createScenarioRelease(
    scenarioRelease: ScenarioRelease,
  ): Observable<ScenarioRelease> {
    const url = this._dataService.scenarioReleaseUrl();
    return this._http.put<ScenarioRelease>(url, scenarioRelease);
  }

  deleteScenarioRelease(scenarioReleaseId: string): Observable<void> {
    const url = this._dataService.scenarioReleaseUrl(scenarioReleaseId);
    return this._http.delete<void>(url);
  }

  getScenarioReleaseDataFile(scenarioReleaseId: string): Observable<string> {
    const url = this._dataService.scenarioReleaseFileUrl(scenarioReleaseId);
    return this._http.get(url, { responseType: 'text' });
  }
}
