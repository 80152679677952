import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppStoreModule } from './store';
import { CoreModule } from '@simx/modules/core';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [AppStoreModule, BrowserAnimationsModule, CoreModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
