import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  HydrateAppState = '[App] Hydrate App State',
  HydrateAppStateSucceeded = '[App] Hydrate App State Succeeded',
  HydrateAppStateFailed = '[App] Hydrate App State Failed',
}

export const hydrateAppState = createAction(ActionTypes.HydrateAppState);
export const hydrateAppStateSucceeded = createAction(
  ActionTypes.HydrateAppStateSucceeded,
  props<{ state: any }>(),
);
export const hydrateAppStateFailed = createAction(
  ActionTypes.HydrateAppStateFailed,
);
