import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationError,
  Router,
} from '@angular/router';
import { AppNavigationItem } from '../models';
import { User } from '@simx/shared/models';
import { AuthenticationService } from '@simx/modules/authentication/services';
import { primaryNavigationItems, userNavigationItems } from '../constants';
import { Actions, ofType } from '@ngrx/effects';
import * as AuthenticationStoreActions from '../../authentication/store/authentication-store.actions';

@Injectable({ providedIn: 'root' })
export class AppNavigationService {
  authenticatedUser: User = null;

  showAppHeader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showAppHeader$: Observable<boolean> = this.showAppHeader.asObservable();
  showAppSidebar: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showAppSidebar$: Observable<boolean> = this.showAppSidebar.asObservable();

  primaryNavigationItems: BehaviorSubject<AppNavigationItem[]> =
    new BehaviorSubject([]);
  primaryNavigationItems$: Observable<AppNavigationItem[]> =
    this.primaryNavigationItems.asObservable();
  userNavigationItems: BehaviorSubject<AppNavigationItem[]> =
    new BehaviorSubject([]);
  userNavigationItems$: Observable<AppNavigationItem[]> =
    this.userNavigationItems.asObservable();

  appViewTitle: BehaviorSubject<string> = new BehaviorSubject(null);
  appViewTitle$: Observable<string> = this.appViewTitle.asObservable();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private actions$: Actions,
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => {
          return this.activatedRoute;
        }),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap(route => route.data),
      )
      .subscribe(routeData => {
        this.refreshAppViewTitle(routeData.title || null);
        this.refreshShowAppHeader(
          routeData.hasOwnProperty('showAppHeader')
            ? routeData.showAppHeader
            : true,
        );
        this.refreshShowAppSidebar(
          routeData.hasOwnProperty('showAppSidebar')
            ? routeData.showAppSidebar
            : true,
        );

        this.actions$
          .pipe(ofType(AuthenticationStoreActions.loginSucceeded))
          .subscribe(() => {
            this.refreshAllowedPrimaryNavigationItems();
            this.refreshAllowedUserNavigationItems();
          });
      });

    this.router.events
      .pipe(filter(event => event instanceof NavigationError))
      .subscribe((_: NavigationError) => {
        this.router.navigate(['']);
      });

    this.authenticationService.getUser$().subscribe((user: User) => {
      this.authenticatedUser = user;
      this.refreshAllowedPrimaryNavigationItems();
      this.refreshAllowedUserNavigationItems();
    });
  }

  private refreshShowAppHeader(state: boolean) {
    this.showAppHeader.next(state);
  }

  private refreshShowAppSidebar(state: boolean) {
    this.showAppSidebar.next(state);
  }

  private async refreshAllowedPrimaryNavigationItems() {
    this.primaryNavigationItems.next(
      await this.getAllowedNavigationItems(primaryNavigationItems),
    );
  }

  private async refreshAllowedUserNavigationItems() {
    this.userNavigationItems.next(
      await this.getAllowedNavigationItems(userNavigationItems),
    );
  }

  private refreshAppViewTitle(title: string) {
    const currentTitle = this.appViewTitle.getValue();
    if (title !== currentTitle) {
      this.appViewTitle.next(title);
    }
  }

  private async getAllowedNavigationItems(
    navigationItemSet: AppNavigationItem[],
  ) {
    const items: AppNavigationItem[] = [];

    for (let item of navigationItemSet) {
      if (!item.permissions.length) {
        items.push(item);
      } else {
        for (let permission of item.permissions) {
          if (
            await firstValueFrom(
              this.authenticationService.isAllowed$(permission),
            )
          ) {
            items.push(item);
            break;
          }
        }
      }
    }

    return items;
  }
}
