// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.simx-table-list-item {
  border-bottom: solid 1px rgb(230, 235, 235);
  display: flex;
}

.simx-table-list-item--content-block {
  flex-grow: 1;
}

.simx-table-list-item--actions-block {
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 12px;
}

.simx-table-list-item.simx-actions-block-left .simx-table-list-item--actions-block {
  order: 1;
}
.simx-table-list-item.simx-actions-block-left .simx-table-list-item--content-block {
  order: 2;
}
.simx-table-list-item.simx-actions-block-right .simx-table-list-item--actions-block {
  order: 2;
}
.simx-table-list-item.simx-actions-block-right .simx-table-list-item--content-block {
  order: 1;
}

.simx-table-list-item--action {
  display: flex;
  height: 25px;
  width: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/ui-elements/components/controls/table-list/table-list-item/table-list-item.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;;AAIA;EACE,2CAAA;EACA,aAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,aAAA;EACE,mBAAA;EACF,YAAA;EACA,cAAA;EACA,eAAA;AADF;;AAMI;EACE,QAAA;AAHN;AAKI;EACE,QAAA;AAHN;AAQI;EACE,QAAA;AANN;AAQI;EACE,QAAA;AANN;;AAWA;EACE,aAAA;EACA,YAAA;EACA,WAAA;AARF","sourcesContent":["@import 'variables';\n\n:host {\n  display: block;\n}\n\n.simx-table-list-item {\n  border-bottom: solid 1px $simxColorLightNeutral;\n  display: flex;\n}\n\n.simx-table-list-item--content-block {\n  flex-grow: 1;\n}\n\n.simx-table-list-item--actions-block {\n  display: flex;\n    align-items: center;\n  flex-grow: 0;\n  flex-shrink: 0;\n  padding: 0 12px;\n}\n\n.simx-table-list-item {\n  &.simx-actions-block-left {\n    .simx-table-list-item--actions-block {\n      order: 1;\n    }\n    .simx-table-list-item--content-block {\n      order: 2;\n    }\n  }\n\n  &.simx-actions-block-right {\n    .simx-table-list-item--actions-block {\n      order: 2;\n    }\n    .simx-table-list-item--content-block {\n      order: 1;\n    }\n  }\n}\n\n.simx-table-list-item--action {\n  display: flex;\n  height: 25px;\n  width: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
