// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simx-entity-detail-header .simx-entity-detail-attribute {
  margin-top: 0;
}
.simx-entity-detail-header .simx-entity-detail-attribute--heading,
.simx-entity-detail-header .simx-entity-detail-attribute--label {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.333334;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/ui-elements/components/entity-detail/entity-detail-header/entity-detail-header.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAGE;;EAEE,gBAAA;EACA,gBAAA;EACA,qBAAA;AADJ","sourcesContent":[".simx-entity-detail-header {\n  .simx-entity-detail-attribute {\n    margin-top: 0;\n  }\n\n  .simx-entity-detail-attribute--heading,\n  .simx-entity-detail-attribute--label {\n    font-size: 1.5em;\n    font-weight: 500;\n    line-height: 1.333334;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
