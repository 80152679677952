import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NEVER, Observable, combineLatest, from, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import * as AuthenticationStoreActions from '../store/authentication-store.actions';
import * as AuthenticationStoreSelectors from '../store/authentication-store.selectors';
import { DataService, UserDataService } from '@simx/shared/services';
import { Router } from '@angular/router';
import {
  Agreement,
  InstitutionUser,
  LoginRequest,
  LoginResponse,
  PasswordChangeRequest,
  PasswordResetRequest,
  User,
  UserSignedAgreement,
} from '@simx/shared/models';
import { GenericPermissions } from '../constants';
import { environment } from '@simx/env';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(
    private readonly _actions$: Actions,
    private readonly _dataService: DataService,
    private readonly _http: HttpClient,
    private readonly _router: Router,
    private readonly _store: Store,
    private readonly _userDataService: UserDataService,
  ) {}

  getIsLoggingIn$(): Observable<boolean> {
    return new Observable<boolean>((observer: any) => {
      const subscription = this._store
        .select(AuthenticationStoreSelectors.selectIsLoggingIn)
        .subscribe((state: boolean) => {
          observer.next(state);
        });

      return () => subscription.unsubscribe();
    });
  }

  getIsLoggedIn$(): Observable<boolean> {
    return new Observable<boolean>((observer: any) => {
      const subscription = this._store
        .select(AuthenticationStoreSelectors.selectIsLoggedIn)
        .subscribe((state: boolean) => {
          observer.next(state);
        });

      return () => subscription.unsubscribe();
    });
  }

  getLoginSucceeded$(): Observable<void> {
    return new Observable<void>((observer: any) => {
      const subscription = this._actions$
        .pipe(ofType(AuthenticationStoreActions.ActionType.LoginSucceeded))
        .subscribe(() => {
          observer.next();
          observer.complete();
        });

      return () => subscription.unsubscribe();
    });
  }

  getLoginFailed$(): Observable<string> {
    return new Observable<string>((observer: any) => {
      const subscription = this._actions$
        .pipe(ofType(AuthenticationStoreActions.ActionType.LoginFailed))
        .subscribe(({ error }) => {
          observer.next(error);
        });

      return () => subscription.unsubscribe();
    });
  }

  getUser$(): Observable<User | null> {
    return new Observable((observer: any) => {
      const subscription = this._store
        .select(AuthenticationStoreSelectors.selectUser)
        .subscribe((user: User | null) => {
          observer.next(user);
        });

      return () => subscription.unsubscribe();
    });
  }

  getUserId$(): Observable<string> {
    return new Observable((observer: any) => {
      const subscription = this._store
        .select(AuthenticationStoreSelectors.selectUserId)
        .subscribe((userId: string) => {
          observer.next(userId);
        });

      return () => subscription.unsubscribe();
    });
  }

  getUserName$(): Observable<string> {
    return new Observable((observer: any) => {
      const subscription = this._store
        .select(AuthenticationStoreSelectors.selectUserName)
        .subscribe((userName: string) => {
          observer.next(userName);
        });

      return () => subscription.unsubscribe();
    });
  }

  getUserPermissions$(): Observable<Array<string>> {
    return new Observable((observer: any) => {
      const subscription = this._store
        .select(AuthenticationStoreSelectors.selectUserPermissions)
        .subscribe((permissions: Array<string>) => {
          observer.next(permissions);
        });

      return () => subscription.unsubscribe();
    });
  }

  getUserInstitutions$(): Observable<Array<InstitutionUser>> {
    return new Observable((observer: any) => {
      const subscription = this._store
        .select(AuthenticationStoreSelectors.selectUserInstitutions)
        .subscribe((institutions: Array<InstitutionUser>) => {
          observer.next(institutions);
        });

      return () => subscription.unsubscribe();
    });
  }

  getUserAllowedReleaseTagsForAnyInstitution$(): Observable<Array<string>> {
    return new Observable((observer: any) => {
      const subscription = this._store
        .select(AuthenticationStoreSelectors.selectUserInstitutions)
        .subscribe((institutionUsers: Array<InstitutionUser>) => {
          const releaseTagSet = new Set();
          for (const institutionUser of institutionUsers) {
            if (institutionUser.scenarioReleaseTags) {
              for (const scenarioReleaseTag of institutionUser.scenarioReleaseTags) {
                releaseTagSet.add(scenarioReleaseTag);
              }
            }
          }
          observer.next(Array.from(releaseTagSet));
        });

      return () => subscription.unsubscribe();
    });
  }

  getUserIsInternalUser$(): Observable<boolean> {
    return new Observable((observer: any) => {
      const subscription = this.getUserInstitutions$().subscribe(
        (institutions: Array<InstitutionUser>) => {
          observer.next(
            !!institutions.find(institutionUser =>
              environment.internalInstitutions.includes(
                institutionUser.institutionId,
              ),
            ),
          );
        },
      );

      return () => subscription.unsubscribe();
    });
  }

  getToken$(): Observable<string> {
    return new Observable((observer: any) => {
      const subscription = this._store
        .select(AuthenticationStoreSelectors.selectToken)
        .subscribe((token: string) => {
          observer.next(token);
        });

      return () => subscription.unsubscribe();
    });
  }

  getIsAuthenticating$(): Observable<boolean> {
    return new Observable((observer: any) => {
      const subscription = this._store
        .select(AuthenticationStoreSelectors.selectIsAuthenticating)
        .subscribe((state: boolean) => {
          observer.next(state);
        });

      return () => subscription.unsubscribe();
    });
  }

  getIsAuthenticated$(): Observable<boolean> {
    return new Observable((observer: any) => {
      const subscription = this._store
        .select(AuthenticationStoreSelectors.selectIsAuthenticated)
        .subscribe((state: boolean) => {
          observer.next(state);
        });

      return () => subscription.unsubscribe();
    });
  }

  getUnsignedAgreements$(): Observable<Array<Agreement>> {
    return new Observable((observer: any) => {
      const subscription = this._store
        .select(AuthenticationStoreSelectors.selectUnsignedAgreements)
        .subscribe((unsignedAgreements: Array<Agreement>) => {
          observer.next(unsignedAgreements);
        });

      return () => subscription.unsubscribe();
    });
  }

  getIsSigningAgreement$(): Observable<boolean> {
    return new Observable((observer: any) => {
      const subscription = this._store
        .select(AuthenticationStoreSelectors.selectIsAcceptingAgreement)
        .subscribe((state: boolean) => {
          observer.next(state);
        });

      return () => subscription.unsubscribe();
    });
  }

  getSignAgreementSucceeded$(): Observable<void> {
    return new Observable((observer: any) => {
      const subscription = this._actions$
        .pipe(
          ofType(AuthenticationStoreActions.ActionType.SignAgreementSucceeded),
        )
        .subscribe(() => {
          observer.next();
        });

      return () => subscription.unsubscribe();
    });
  }

  getSignAgreementFailed$(): Observable<string> {
    return new Observable((observer: any) => {
      const subscription = this._actions$
        .pipe(ofType(AuthenticationStoreActions.ActionType.SignAgreementFailed))
        .subscribe(({ error }) => {
          observer.next(error);
        });

      return () => subscription.unsubscribe();
    });
  }

  requestLogin(loginRequest: LoginRequest): void {
    this._store.dispatch(
      AuthenticationStoreActions.requestLogin({
        data: loginRequest,
      }),
    );
  }

  authenticateUser(
    loginRequest: LoginRequest,
  ): Observable<
    | TypedAction<AuthenticationStoreActions.ActionType.AuthenticationSucceeded>
    | TypedAction<AuthenticationStoreActions.ActionType.AuthenticationFailed>
  > {
    loginRequest.email = loginRequest.email.toLowerCase();

    const url = this._dataService.loginPortalUrl();
    return this._http.post(url, loginRequest).pipe(
      map((loginResponse: LoginResponse) =>
        AuthenticationStoreActions.authenticationSucceeded({
          data: loginResponse,
        }),
      ),
      catchError((error: HttpErrorResponse) => {
        let errorMessage: string;
        switch (error.status) {
          case 0:
            errorMessage = 'Service unavailable';
            break;
          case 403:
            errorMessage = 'Invalid user name or password';
            break;
          default:
            errorMessage = 'Unknown error occurred';
        }
        return of(
          AuthenticationStoreActions.authenticationFailed({
            error: errorMessage,
          }),
        );
      }),
    );
  }

  checkForUnsignedAgreements(): Observable<
    | TypedAction<AuthenticationStoreActions.ActionType.UnsignedAgreementsCheckSucceeded>
    | TypedAction<AuthenticationStoreActions.ActionType.UnsignedAgreementsCheckFailed>
  > {
    return combineLatest([this.getUser$(), this.getToken$()]).pipe(
      filter(([user, token]) => user !== null && token !== null),
      take(1),
      switchMap(([user]) =>
        from(this._userDataService.getUnsignedAgreements(user.userId)),
      ),
      map((unsignedAgreements: Array<Agreement>) =>
        AuthenticationStoreActions.unsignedAgreementsCheckSucceeded({
          data: unsignedAgreements,
        }),
      ),
      catchError((error: HttpErrorResponse) =>
        of(
          AuthenticationStoreActions.unsignedAgreementsCheckFailed({
            error: error.message,
          }),
        ),
      ),
    );
  }

  processUnsignedAgreements(unsignedAgreements: Array<Agreement>): void {
    if (unsignedAgreements.length) {
      this._router.navigate(['/agreement']);
    } else {
      this._store.dispatch(AuthenticationStoreActions.loginSucceeded());
    }
  }

  requestSignAgreement(agreementId: string): void {
    this._store.dispatch(
      AuthenticationStoreActions.requestSignAgreement({ agreementId }),
    );
  }

  signAgreement(
    userId: string,
    agreementId: string,
  ): Observable<
    | TypedAction<AuthenticationStoreActions.ActionType.SignAgreementSucceeded>
    | TypedAction<AuthenticationStoreActions.ActionType.SignAgreementFailed>
  > {
    return from(this._userDataService.signAgreement(userId, agreementId)).pipe(
      map((userSignedAgreement: UserSignedAgreement) =>
        AuthenticationStoreActions.signAgreementSucceeded({
          data: userSignedAgreement,
        }),
      ),
      catchError((error: HttpErrorResponse) =>
        of(
          AuthenticationStoreActions.signAgreementFailed({
            error: error.message,
          }),
        ),
      ),
    );
  }

  completeLogin(): void {
    this._router.navigate(['/']);
  }

  requestLogout(): void {
    this._store.dispatch(AuthenticationStoreActions.requestLogout());
  }

  completeLogout(): void {
    this._router.navigate(['/login']);
  }

  validateToken(): Promise<any> {
    const url = this._dataService.userUrl();
    return this._http.get(url).toPromise();
  }

  isAllowed$(permission: string): Observable<boolean> {
    return new Observable((observer: any) => {
      const subscription = combineLatest([
        this.getUserPermissions$(),
        this.getUserInstitutions$(),
      ]).subscribe(([userPermissions, userInstitutions]) => {
        let isAllowed = false;
        if (permission === GenericPermissions.IsAuthenticated) {
          isAllowed = true;
        }
        if (!isAllowed) {
          isAllowed = userPermissions.includes(permission);
        }
        if (!isAllowed) {
          for (let userInstitution of userInstitutions) {
            if (userInstitution.institutionPermissions) {
              isAllowed =
                userInstitution.institutionPermissions.includes(permission);
              if (isAllowed) break;
            }
          }
        }
        observer.next(isAllowed);
      });

      return () => subscription.unsubscribe();
    });
  }

  isAllowedForInstitution$(
    permission: string,
    institutionId: string,
  ): Observable<boolean> {
    return new Observable((observer: any) => {
      const subscription = this.getUserInstitutions$().subscribe(
        userInstitutions => {
          const institution = userInstitutions.find(
            userInstitution =>
              userInstitution.institution.institutionId === institutionId,
          );
          const isAllowed = institution
            ? institution.institutionPermissions?.includes(permission)
            : false;
          observer.next(isAllowed);
        },
      );

      return () => subscription.unsubscribe();
    });
  }

  isAllowedForAnyInstitution$(permission: string): Observable<boolean> {
    return new Observable((observer: any) => {
      const subscription = this.getUserInstitutions$().subscribe(
        userInstitutions => {
          let isAllowed = false;
          for (let institution of userInstitutions) {
            if (institution.institutionPermissions?.includes(permission)) {
              isAllowed = true;
              break;
            }
          }
          observer.next(isAllowed);
        },
      );

      return () => subscription.unsubscribe();
    });
  }

  updateUser(data: User): Promise<boolean | string> {
    return new Promise((resolve, reject) => {
      if (data.hasOwnProperty('email')) {
        data.email = data.email.toLowerCase();
      }

      this._userDataService
        .updateUser(data)
        .pipe(
          catchError((error: string) => {
            reject(error);
            return NEVER;
          }),
        )
        .subscribe((user: User) => {
          this._store.dispatch(AuthenticationStoreActions.setUser({ user }));
          resolve(true);
        });
    });
  }

  updateUserPassword(data: PasswordChangeRequest): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._userDataService
        .updateUserPassword(data)
        .pipe(
          catchError((error: string) => {
            reject(error);
            return NEVER;
          }),
        )
        .subscribe((response: LoginResponse) => {
          this._store.dispatch(
            AuthenticationStoreActions.setToken({
              token: response.token,
            }),
          );
          resolve(true);
        });
    });
  }

  resetUserPassword(data: PasswordResetRequest): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._userDataService
        .resetUserPassword(data)
        .pipe(
          catchError((error: any) => {
            reject(error);
            return NEVER;
          }),
        )
        .subscribe(() => {
          resolve(true);
        });
    });
  }
}
