import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as InstitutionStoreActions from '../store/institution-store.actions';
import * as InstitutionStoreSelectors from '../store/institution-store.selectors';
import { NEVER, Observable, catchError, map, of, take } from 'rxjs';
import {
  Institution,
  InstitutionScenarioPackage,
  ScenarioPackage,
} from '@simx/shared/models';
import { Permissions } from '@simx/shared/constants';
import {
  InstitutionDataService,
  InstitutionScenarioPackageDataService,
  ScenarioPackageDataService,
} from '@simx/shared/services';
import { AuthenticationService } from '@simx/modules/authentication/services';

@Injectable({ providedIn: 'root' })
export class InstitutionAdministrationService {
  scenarioPackageListStore: Array<ScenarioPackage> = null;
  scenarioPackageWithItemsStore: any = {};

  institutionExpirationWindowMax: number = 30;
  urgentInstitutionExpirationWindowMax: number = 15;

  constructor(
    private store: Store,
    private institutionDataService: InstitutionDataService,
    private institutionScenarioPackageDataService: InstitutionScenarioPackageDataService,
    private scenarioPackageDataService: ScenarioPackageDataService,
    private authenticationService: AuthenticationService,
  ) {
    this.authenticationService.getIsLoggedIn$().subscribe((state: boolean) => {
      if (!state) {
        this.resetLocalStores();
      }
    });
  }

  authenticatedUserCan$(permission: Permissions): Observable<boolean> {
    return this.authenticationService.isAllowed$(permission);
  }

  resetLocalStores() {
    this.scenarioPackageListStore = null;
    this.scenarioPackageWithItemsStore = {};
  }

  fetchInstitutionList() {
    return this.institutionDataService.getInstitutions().pipe(
      map((institutions: Array<Institution>) =>
        InstitutionStoreActions.loadInstitutionListSucceeded({ institutions }),
      ),
      catchError((error: string) =>
        of(InstitutionStoreActions.loadInstitutionListFailed({ error })),
      ),
    );
  }

  getInstitutionList$(): Observable<Array<Institution>> {
    return new Observable((observer: any) => {
      this.store
        .select(InstitutionStoreSelectors.selectInstitutionList)
        .subscribe((institutions: Array<Institution>) => {
          observer.next(institutions);
        });
      this.store.dispatch(InstitutionStoreActions.checkInstitutionListLoaded());
    });
  }

  getIsLoadingInstitutionList$(): Observable<boolean> {
    return new Observable((observer: any) => {
      this.store
        .select(InstitutionStoreSelectors.selectIsLoadingInstitutionList)
        .subscribe((state: boolean) => {
          observer.next(state);
        });
    });
  }

  getHasLoadedInstitutionList$(): Observable<boolean> {
    return new Observable((observer: any) => {
      this.store
        .select(InstitutionStoreSelectors.selectHasLoadedInstitutionList)
        .subscribe((state: boolean) => {
          observer.next(state);
        });
    });
  }

  getLoadInstitutionListError$(): Observable<string> {
    return new Observable((observer: any) => {
      this.store
        .select(InstitutionStoreSelectors.selectLoadInstitutionListError)
        .subscribe((error: string) => {
          observer.next(error);
        });
    });
  }

  clearInstitutionDataAndLoadInstitutionList() {
    this.store.dispatch(
      InstitutionStoreActions.clearInstitutionStoreAndLoadInstitutionList(),
    );
  }

  getInstitution(institutionId: string): Promise<Institution> {
    return new Promise((resolve, reject) => {
      this.institutionDataService
        .getInstitution(institutionId)
        .pipe(
          catchError((error: any) => {
            reject(error);
            return NEVER;
          }),
        )
        .subscribe((institution: Institution) => {
          resolve(institution);
        });
    });
  }

  getInstitutionScenarioPackageItemsForInstitution(
    institutionId: string,
  ): Promise<Array<InstitutionScenarioPackage>> {
    return new Promise((resolve, reject) => {
      this.institutionScenarioPackageDataService
        .getInstitutionScenarioPackageItemsForInstitution(institutionId, {
          scenarioPackage: true,
          scenarioPackageItems: true,
          scenarioPackageItemsScenario: true,
        })
        .pipe(
          catchError((error: any) => {
            reject(error);
            return NEVER;
          }),
        )
        .subscribe(
          (institutionScenarioPackages: Array<InstitutionScenarioPackage>) => {
            resolve(institutionScenarioPackages);
          },
        );
    });
  }

  setInstitutionScenarioPackageItemsForInstitution(
    institutionId: string,
    institutionScenarioPackageItems: Array<InstitutionScenarioPackage>,
  ): Promise<Array<InstitutionScenarioPackage>> {
    return new Promise((resolve, reject) => {
      this.institutionScenarioPackageDataService
        .setInstitutionScenarioPackageItemsForInstitution(
          institutionId,
          institutionScenarioPackageItems,
          {
            scenarioPackage: true,
            scenarioPackageItems: true,
            scenarioPackageItemsScenario: true,
          },
        )
        .pipe(
          catchError((error: any) => {
            reject(error);
            return NEVER;
          }),
        )
        .subscribe(
          (institutionScenarioPackages: Array<InstitutionScenarioPackage>) => {
            resolve(institutionScenarioPackages);
          },
        );
    });
  }

  getScenarioPackageWithItems(
    scenarioPackageId: string,
  ): Promise<ScenarioPackage> {
    return new Promise((resolve, reject) => {
      if (
        this.scenarioPackageWithItemsStore.hasOwnProperty(scenarioPackageId)
      ) {
        resolve(this.scenarioPackageWithItemsStore[scenarioPackageId]);
      } else {
        const includes = {
          items: true,
          itemsScenario: true,
        };

        this.scenarioPackageDataService
          .getScenarioPackage(scenarioPackageId, includes)
          .pipe(
            catchError((error: any) => {
              reject(error);
              return NEVER;
            }),
          )
          .subscribe((scenarioPackage: ScenarioPackage) => {
            this.scenarioPackageWithItemsStore[scenarioPackageId] =
              scenarioPackage;

            resolve(scenarioPackage);
          });
      }
    });
  }

  getAuthenticatedUserAllowedScenarioPackages(): Promise<
    Array<ScenarioPackage>
  > {
    return new Promise((resolve, reject) => {
      if (this.scenarioPackageListStore) {
        resolve([...this.scenarioPackageListStore]);
      } else {
        this.scenarioPackageDataService
          .getScenarioPackages()
          .pipe(
            take(1),
            catchError((error: any) => {
              reject(error);
              return NEVER;
            }),
          )
          .subscribe((scenarioPackages: Array<ScenarioPackage>) => {
            this.scenarioPackageListStore = scenarioPackages;
            resolve([...this.scenarioPackageListStore]);
          });
      }
    });
  }

  createInstitution(institution: Institution): Promise<Institution> {
    return new Promise((resolve, reject) => {
      this.institutionDataService
        .createInstitution(institution)
        .pipe(
          catchError((error: any) => {
            reject(error);
            return NEVER;
          }),
        )
        .subscribe((institution: Institution) => {
          this.store.dispatch(
            InstitutionStoreActions.addInstitutionToInstitutionList({
              institution,
            }),
          );

          resolve(institution);
        });
    });
  }

  updateInstitution(institution: Institution): Promise<Institution> {
    return new Promise((resolve, reject) => {
      this.institutionDataService
        .updateInstitution(institution)
        .pipe(
          catchError((error: any) => {
            reject(error);
            return NEVER;
          }),
        )
        .subscribe((institution: Institution) => {
          this.store.dispatch(
            InstitutionStoreActions.updateInstitutionInInstitutionList({
              institution,
            }),
          );

          resolve(institution);
        });
    });
  }

  reactivateInstitution(institutionId: string): Promise<Institution> {
    return new Promise((resolve, reject) => {
      this.institutionDataService
        .reactivateInstitution(institutionId)
        .pipe(
          catchError((error: string) => {
            reject(error);
            return NEVER;
          }),
        )
        .subscribe((response: Institution) => {
          this.store.dispatch(
            InstitutionStoreActions.updateInstitutionInInstitutionList({
              institution: response,
            }),
          );

          resolve(response);
        });
    });
  }

  reactivateInstitutions(
    institutionIds: Array<string>,
  ): Promise<Array<Institution>> {
    return new Promise((resolve, reject) => {
      const reactivateTasks = institutionIds.map((institutionId: string) =>
        this.reactivateInstitution(institutionId),
      );
      Promise.all(reactivateTasks)
        .then((responses: Array<Institution>) => {
          resolve(responses);
        })
        .catch((error: string) => {
          reject(error);
        });
    });
  }
}
