import { ErrorHandler, Injectable } from '@angular/core';
import { AppLoggingService } from '../services';

@Injectable()
export class ErrorHandlerInterceptor extends ErrorHandler {
  constructor(private _appLoggingService: AppLoggingService) {
    super();
  }

  override handleError(error: any): void {
    this._appLoggingService.logError(error);
  }
}
