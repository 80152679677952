import { Component, Input, OnInit } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { AuthenticationService } from '@simx/modules/authentication/services';
import { TaxonomyService } from '@simx/modules/taxonomy/services';
import { EntityDetailDisplayModes } from '@simx/shared/constants';
import { Taxonomies } from '@simx/modules/taxonomy/constants';
import { TaxonomyItem } from '@simx/shared/models';
import { CheckListSelectItem } from '@simx/modules/ui-elements/models';

@Component({
  selector: 'permissions-list',
  templateUrl: './permissions-list.component.html',
  styleUrls: ['./permissions-list.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PermissionsListComponent,
    },
  ],
})
export class PermissionsListComponent implements ControlValueAccessor, OnInit {
  @Input() id: string;
  @Input() displayMode: EntityDetailDisplayModes;
  @Input() permissions: Array<string>;
  @Input() taxonomyName: Taxonomies;
  @Input() institutionId: string;

  allowedPermissions: Array<string> = [];
  hiddenPermissions: Array<string> = [];

  form: FormGroup;
  checkListSelectItems: Array<CheckListSelectItem> = [];

  displayModes = EntityDetailDisplayModes;

  onChange = _ => {};
  onTouched = () => {};
  touched: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private taxonomyService: TaxonomyService,
  ) {}

  ngOnInit() {
    this.initializeForm();

    this.taxonomyService
      .getTaxonomy(this.taxonomyName)
      .then(async (taxonomy: Array<TaxonomyItem>) => {
        for (let taxonomyItem of taxonomy) {
          const isAllowed = this.institutionId
            ? await firstValueFrom(
                this.authenticationService.isAllowedForInstitution$(
                  taxonomyItem.term,
                  this.institutionId,
                ),
              )
            : await firstValueFrom(
                this.authenticationService.isAllowed$(taxonomyItem.term),
              );
          if (isAllowed) {
            this.checkListSelectItems.push({
              value: taxonomyItem.term,
              label: taxonomyItem.label,
            });
          }
        }
      });
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      permissions: [[]],
    });
    this.form.valueChanges.subscribe(data => {
      this.allowedPermissions = data.permissions;
      this.permissions = [
        ...this.hiddenPermissions,
        ...this.allowedPermissions,
      ];
      this.onChange(this.permissions);
    });
  }

  markAsTouched() {
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }

  writeValue(value: Array<string>) {
    this.permissions = value;
    this.refreshCalculatedPermissions();
  }

  async refreshCalculatedPermissions() {
    const allowedPermissions = [];
    const hiddenPermissions = [];
    for (let permission of this.permissions) {
      const isAllowed = this.institutionId
        ? await firstValueFrom(
            this.authenticationService.isAllowedForInstitution$(
              permission,
              this.institutionId,
            ),
          )
        : await firstValueFrom(
            this.authenticationService.isAllowed$(permission),
          );
      if (isAllowed) {
        allowedPermissions.push(permission);
      } else {
        hiddenPermissions.push(permission);
      }
    }

    this.allowedPermissions = allowedPermissions;
    this.hiddenPermissions = hiddenPermissions;

    this.form.controls.permissions.setValue(this.allowedPermissions, {
      emitEvent: false,
    });
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
}
