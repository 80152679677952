import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from '@simx/env';
import { System } from '../../../../system';
import { User } from '@simx/shared/models';
import { AuthenticationService } from '@simx/modules/authentication/services';

@Injectable({
  providedIn: 'root',
})
export class AppLoggingService {
  private _errorLoggingIsActive: boolean = false;

  get _shouldLogErrors(): boolean {
    return environment.logErrors;
  }

  constructor(private _authenticationService: AuthenticationService) {}

  initializeErrorLogging(): void {
    if (!this._shouldLogErrors) return;
    if (this._errorLoggingIsActive) return;

    Sentry.init({
      dsn: environment.sentryDsn,
      environment: environment.name,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      release: System.appReleaseVersion,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
      tracePropagationTargets: [
        'localhost',
        'https://api-dev.app.simx-infra.com',
        'https://api-beta.app.simx-infra.com',
        'https://api-prod.app.simx-infra.com',
      ],
    });

    this._authenticationService.getUser$().subscribe((user: User | null) => {
      const sentryUserData = user
        ? {
            email: user.email,
            id: user.userId,
            name: user.name,
          }
        : null;
      Sentry.setUser(sentryUserData);
    });

    this._errorLoggingIsActive = true;
  }

  logError(error: any): void {
    console.error(error);

    if (this._shouldLogErrors && this._errorLoggingIsActive) {
      Sentry.captureException(error);
    }
  }
}
