import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, takeWhile } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as TaxonomyStoreActions from '../store/taxonomy-store.actions';
import * as TaxonomyStoreSelectors from '../store/taxonomy-store.selectors';
import { Taxonomies } from '../constants/taxonomies.constant';
import { TaxonomyItem } from '@simx/shared/models';
import { TaxonomyDataService } from '@simx/shared/services';

@Injectable({ providedIn: 'root' })
export class TaxonomyService {
  constructor(
    private store: Store,
    private taxonomyDataService: TaxonomyDataService,
  ) {}

  requestTaxonomy(taxonomyName: Taxonomies) {
    this.store
      .select(TaxonomyStoreSelectors.selectIsLoadingTaxonomies)
      .pipe(takeWhile(isLoading => isLoading.length > 0, true))
      .subscribe(isLoading => {
        if (isLoading.length === 0) {
          this.store.dispatch(
            TaxonomyStoreActions.checkTaxonomyLoaded({ taxonomyName }),
          );
        }
      });
  }

  fetchTaxonomyByName(taxonomyName: Taxonomies) {
    return this.taxonomyDataService.getTaxonomyByName(taxonomyName).pipe(
      map((taxonomyItems: Array<TaxonomyItem>) =>
        TaxonomyStoreActions.loadTaxonomySucceeded({
          taxonomyName,
          taxonomyItems,
        }),
      ),
      catchError((error: string) =>
        of(
          TaxonomyStoreActions.loadTaxonomyFailed({
            taxonomyName,
            error,
          }),
        ),
      ),
    );
  }

  getTaxonomy(taxonomyName: Taxonomies): Promise<Array<TaxonomyItem>> {
    return new Promise((resolve, reject) => {
      this.getTaxonomy$(taxonomyName)
        .pipe(takeWhile(taxonomy => taxonomy === undefined, true))
        .subscribe((taxonomy: Array<TaxonomyItem>) => {
          if (taxonomy !== undefined) {
            resolve(taxonomy);
          }
        });
    });
  }

  getTaxonomy$(taxonomyName: Taxonomies): Observable<Array<TaxonomyItem>> {
    this.requestTaxonomy(taxonomyName);

    return new Observable<Array<TaxonomyItem>>((observer: any) => {
      this.store
        .select(TaxonomyStoreSelectors.selectTaxonomy, { taxonomyName })
        .subscribe((taxonomy: Array<TaxonomyItem>) => {
          observer.next(taxonomy);
        });
    });
  }

  getTaxonomyItem(
    term: string,
    taxonomyName: Taxonomies,
  ): Promise<TaxonomyItem> {
    return new Promise((resolve, reject) => {
      this.getTaxonomyItem$(term, taxonomyName)
        .pipe(takeWhile(taxonomyItem => taxonomyItem === undefined, true))
        .subscribe((taxonomyItem: TaxonomyItem) => {
          if (taxonomyItem !== undefined) {
            resolve(taxonomyItem);
          }
        });
    });
  }

  getTaxonomyItem$(
    term: string,
    taxonomyName: Taxonomies,
  ): Observable<TaxonomyItem> {
    this.requestTaxonomy(taxonomyName);

    return new Observable<TaxonomyItem>((observer: any) => {
      this.store
        .select(TaxonomyStoreSelectors.selectTaxonomyItem, {
          term,
          taxonomyName,
        })
        .subscribe((taxonomyItem: TaxonomyItem) => {
          observer.next(taxonomyItem);
        });
    });
  }
}
