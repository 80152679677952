import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { TaxonomyService } from '../services';
import { Taxonomies } from '../constants';
import { TaxonomyItem } from '@simx/shared/models';

@Pipe({ name: 'taxonomyTermLabel' })
export class TaxonomyTermLabelPipe implements PipeTransform {
  constructor(private taxonomyService: TaxonomyService) {}

  transform(term: string, taxonomyName: Taxonomies): Observable<string> {
    return new Observable((observer: any) => {
      this.taxonomyService
        .getTaxonomyItem(term, taxonomyName)
        .then((taxonomyItem: TaxonomyItem) => {
          observer.next(taxonomyItem.label);
        });
    });
  }
}
