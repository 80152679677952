import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { Scenario } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class ScenarioDataService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _http: HttpClient,
  ) {}

  getScenario(scenarioId: string): Observable<Scenario> {
    const url = this._dataService.scenarioUrl(scenarioId);
    return this._http.get<Scenario>(url);
  }

  updateScenario(scenario: Scenario): Observable<Scenario> {
    const url = this._dataService.scenarioUrl(scenario.scenarioId);
    return this._http.post<Scenario>(url, scenario);
  }

  getScenarios(): Observable<Array<Scenario>> {
    const url = this._dataService.scenariosUrl();
    return this._http.get<Array<Scenario>>(url);
  }
}
