import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { ScenarioPackage } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class ScenarioPackageDataService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _http: HttpClient,
  ) {}

  getScenarioPackages(): Observable<Array<ScenarioPackage>> {
    const url = this._dataService.scenarioPackagesUrl();
    return this._http.get<Array<ScenarioPackage>>(url);
  }

  getScenarioPackage(
    scenarioPackageId: string,
    includes?: any,
  ): Observable<ScenarioPackage> {
    const url = this._dataService.scenarioPackageUrl(
      scenarioPackageId,
      includes,
    );
    return this._http.get<ScenarioPackage>(url);
  }

  createScenarioPackage(
    scenarioPackage: ScenarioPackage,
  ): Observable<ScenarioPackage> {
    const url = this._dataService.scenarioPackageUrl();
    return this._http.put<ScenarioPackage>(url, scenarioPackage);
  }

  updateScenarioPackage(
    scenarioPackage: ScenarioPackage,
    includes?: any,
  ): Observable<ScenarioPackage> {
    const url = this._dataService.scenarioPackageUrl(null, includes);
    return this._http.post<ScenarioPackage>(url, scenarioPackage);
  }

  deleteScenarioPackage(scenarioPackageId: string): Observable<unknown> {
    const url = this._dataService.scenarioPackageUrl(scenarioPackageId);
    return this._http.delete(url);
  }
}
