// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simx-authenticate--inputs-block {
  padding: 15px 30px;
}
.simx-authenticate--inputs-block .simx-field-label-container {
  width: 125px;
}
.simx-authenticate--inputs-block .simx-field-value-container {
  width: 250px;
}

.simx-authenticate--message-block {
  height: 1.3em;
  padding: 0 30px;
}

.simx-authenticate--message-container {
  position: relative;
}
.simx-authenticate--message-container .simx-error-message {
  position: absolute;
  width: 100%;
  justify-content: flex-end;
}

.simx-authenticate--actions-block {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.simx-authenticate--actions-block .simx-action-button {
  width: 125px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/authentication/components/authenticate/authenticate.component.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAGE;EACE,YAAA;AADJ;AAIE;EACE,YAAA;AAFJ;;AAMA;EACE,aAAA;EACA,eAAA;AAHF;;AAMA;EACE,kBAAA;AAHF;AAKE;EACE,kBAAA;EACA,WAAA;EACA,yBAAA;AAHJ;;AAOA;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAJF;AAME;EACE,YAAA;AAJJ","sourcesContent":["@import 'variables';\n\n.simx-authenticate--inputs-block {\n  padding: 15px 30px;\n\n  .simx-field-label-container {\n    width: 125px;\n  }\n\n  .simx-field-value-container {\n    width: 250px;\n  }\n}\n\n.simx-authenticate--message-block {\n  height: 1.3em;\n  padding: 0 30px;\n}\n\n.simx-authenticate--message-container {\n  position: relative;\n\n  .simx-error-message {\n    position: absolute;\n    width: 100%;\n    justify-content: flex-end;\n  }\n}\n\n.simx-authenticate--actions-block {\n  padding: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  .simx-action-button {\n    width: 125px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
