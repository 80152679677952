import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { InstitutionScenarioPackage } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class InstitutionScenarioPackageDataService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _http: HttpClient,
  ) {}

  createInstitutionScenarioPackage(
    institutionScenarioPackage: InstitutionScenarioPackage,
  ): Observable<InstitutionScenarioPackage> {
    const url = this._dataService.institutionScenarioPackageUrl();
    return this._http.put<InstitutionScenarioPackage>(
      url,
      institutionScenarioPackage,
    );
  }

  getInstitutionScenarioPackageItemsForInstitution(
    institutionId: string,
    includes?: any,
  ): Observable<Array<InstitutionScenarioPackage>> {
    const url = this._dataService.institutionScenarioPackagesUrl(
      institutionId,
      includes,
    );
    return this._http.get<Array<InstitutionScenarioPackage>>(url);
  }

  getInstitutionScenarioPackageItemsForScenarioPackage(
    scenarioPackageId: string,
    includes?: any,
  ): Observable<Array<InstitutionScenarioPackage>> {
    const url = this._dataService.scenarioPackageInstitutionsUrl(
      scenarioPackageId,
      includes,
    );
    return this._http.get<Array<InstitutionScenarioPackage>>(url);
  }

  setInstitutionScenarioPackageItemsForScenarioPackage(
    scenarioPackageId: string,
    institutionScenarioPackageItems: Array<InstitutionScenarioPackage>,
    includes?: any,
  ): Observable<Array<InstitutionScenarioPackage>> {
    const url = this._dataService.scenarioPackageInstitutionsUrl(
      scenarioPackageId,
      includes,
    );
    return this._http.post<Array<InstitutionScenarioPackage>>(
      url,
      institutionScenarioPackageItems,
    );
  }

  setInstitutionScenarioPackageItemsForInstitution(
    institutionId: string,
    institutionScenarioPackageItems: Array<InstitutionScenarioPackage>,
    includes?: any,
  ): Observable<Array<InstitutionScenarioPackage>> {
    const url = this._dataService.institutionScenarioPackagesUrl(
      institutionId,
      includes,
    );
    return this._http.post<Array<InstitutionScenarioPackage>>(
      url,
      institutionScenarioPackageItems,
    );
  }
}
