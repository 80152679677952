import { NgModule } from '@angular/core';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@simx/env';
import { hydrationMetaReducer, reducer } from './app-store.reducer';
import { AppStoreEffects } from './app-store.effects';

const metaReducers: Array<MetaReducer> = [hydrationMetaReducer];

@NgModule({
  imports: [
    StoreModule.forRoot({ app: reducer }, { metaReducers }),
    EffectsModule.forRoot([AppStoreEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
})
export class AppStoreModule {}
