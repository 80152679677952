import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import {
  ScenarioRevision,
  ScenarioRevisionIncludes,
} from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class ScenarioRevisionDataService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _http: HttpClient,
  ) {}

  getScenarioRevisionsForScenario(
    scenarioId: string,
    includes?: ScenarioRevisionIncludes,
  ): Observable<Array<ScenarioRevision>> {
    const url = this._dataService.scenarioRevisionsUrl(scenarioId, includes);
    return this._http.get<Array<ScenarioRevision>>(url);
  }

  createScenarioRevision(
    scenarioRevision: ScenarioRevision,
    includes?: ScenarioRevisionIncludes,
  ): Observable<ScenarioRevision> {
    const url = this._dataService.scenarioRevisionUrl(null, includes);
    return this._http.put<ScenarioRevision>(url, scenarioRevision);
  }

  deleteScenarioRevision(scenarioRevisionId: string): Observable<void> {
    const url = this._dataService.scenarioRevisionUrl(scenarioRevisionId);
    return this._http.delete<void>(url);
  }

  getScenarioRevisionDataFile(scenarioRevisionId: string): Observable<string> {
    const url = this._dataService.scenarioRevisionFileUrl(scenarioRevisionId);
    return this._http.get(url, { responseType: 'text' });
  }
}
