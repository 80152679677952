import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { ScenarioReleaseTag, ScenarioReleaseTagMap } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class ScenarioReleaseTagDataService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _http: HttpClient,
  ) {}

  getHeadScenarioReleasesForScenario(
    scenarioId: string,
    releaseTags?: Array<string>,
  ): Observable<Array<ScenarioReleaseTag>> {
    let params = null;
    if (releaseTags) {
      params = {
        releaseTag: releaseTags.join(','),
      };
    }
    const url = this._dataService.headScenarioReleaseUrl(
      scenarioId,
      null,
      params,
    );
    return this._http.get<Array<ScenarioReleaseTag>>(url);
  }

  setHeadScenarioReleaseForScenarioAndReleaseTag(
    scenarioId: string,
    releaseTag: string,
    scenarioReleaseId: string,
  ): Observable<ScenarioReleaseTagMap> {
    const url = this._dataService.headScenarioReleaseUrl(scenarioId);
    const body: ScenarioReleaseTagMap = {
      [releaseTag]: {
        scenarioReleaseId,
      },
    };
    return this._http.put<ScenarioReleaseTagMap>(url, body);
  }

  unsetHeadScenarioReleaseForScenarioAndReleaseTag(
    scenarioId: string,
    releaseTag: string,
  ): Observable<void> {
    const url = this._dataService.headScenarioReleaseUrl(
      scenarioId,
      releaseTag,
    );
    return this._http.delete<void>(url);
  }
}
