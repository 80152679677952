import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { TaxonomyItem } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class TaxonomyDataService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _http: HttpClient,
  ) {}

  getTaxonomyByName(taxonomyName: string): Observable<Array<TaxonomyItem>> {
    const url = this._dataService.taxonomyUrl(taxonomyName);
    return this._http.get<Array<TaxonomyItem>>(url);
  }
}
