import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as TaxonomyStoreActions from './taxonomy-store.actions';
import * as TaxonomyStoreSelectors from './taxonomy-store.selectors';
import { AuthenticationStoreActions } from '@simx/modules/authentication/store';
import { TaxonomyService } from '../services';

@Injectable()
export class TaxonomyStoreEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private taxonomyService: TaxonomyService,
  ) {}

  checkTaxonomyLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaxonomyStoreActions.ActionTypes.CheckTaxonomyLoaded),
      withLatestFrom(
        this.store.select(TaxonomyStoreSelectors.selectHasLoadedTaxonomies),
        this.store.select(TaxonomyStoreSelectors.selectIsLoadingTaxonomies),
      ),
      filter(
        ([{ taxonomyName }, hasLoaded, isLoading]) =>
          !hasLoaded.includes(taxonomyName) &&
          !isLoading.includes(taxonomyName),
      ),
      map(([{ taxonomyName }]) => taxonomyName),
      map(taxonomyName =>
        TaxonomyStoreActions.loadTaxonomyRequested({ taxonomyName }),
      ),
    ),
  );

  loadTaxonomyRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaxonomyStoreActions.ActionTypes.LoadTaxonomyRequested),
      switchMap(({ taxonomyName }) =>
        this.taxonomyService.fetchTaxonomyByName(taxonomyName),
      ),
    ),
  );

  loadTaxonomySucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaxonomyStoreActions.ActionTypes.LoadTaxonomySucceeded),
      map(({ taxonomyName, taxonomyItems }) =>
        TaxonomyStoreActions.loadTaxonomy({
          taxonomyName,
          taxonomyItems,
        }),
      ),
    ),
  );

  clearTaxonomies = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationStoreActions.ActionType.LogoutSucceeded),
      map(() => TaxonomyStoreActions.clearTaxonomies()),
    ),
  );
}
