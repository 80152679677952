// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simx-reset-password--instructions-block {
  margin: 30px 0;
  padding: 0 30px;
  width: 435px;
}

.simx-reset-password--inputs-block {
  margin: 30px 0;
  padding: 0 30px;
}
.simx-reset-password--inputs-block .simx-field-label-container {
  width: 150px;
}
.simx-reset-password--inputs-block .simx-field-value-container {
  width: 225px;
}

.simx-reset-password--message-block {
  height: 1.3em;
  padding: 0 30px;
}

.simx-reset-password--message-container {
  position: relative;
}
.simx-reset-password--message-container .simx-user-message,
.simx-reset-password--message-container .simx-error-message {
  position: absolute;
  width: 100%;
}
.simx-reset-password--message-container .simx-error-message {
  justify-content: flex-end;
}

.simx-reset-password--actions-block {
  margin: 30px 0;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/authentication/components/reset-password/reset-password.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,YAAA;AAAJ;;AAIA;EACE,aAAA;EACA,eAAA;AADF;;AAIA;EACE,kBAAA;AADF;AAGE;;EAEE,kBAAA;EACA,WAAA;AADJ;AAGE;EACE,yBAAA;AADJ;;AAKA;EACE,cAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAFF","sourcesContent":[".simx-reset-password--instructions-block {\n  margin: 30px 0;\n  padding: 0 30px;\n  width: 435px;\n}\n\n.simx-reset-password--inputs-block {\n  margin: 30px 0;\n  padding: 0 30px;\n\n  .simx-field-label-container {\n    width: 150px;\n  }\n\n  .simx-field-value-container {\n    width: 225px;\n  }\n}\n\n.simx-reset-password--message-block {\n  height: 1.3em;\n  padding: 0 30px;\n}\n\n.simx-reset-password--message-container {\n  position: relative;\n\n  .simx-user-message,\n  .simx-error-message {\n    position: absolute;\n    width: 100%;\n  }\n  .simx-error-message {\n    justify-content: flex-end;\n  }\n}\n\n.simx-reset-password--actions-block {\n  margin: 30px 0;\n  padding: 0 30px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
